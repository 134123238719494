:root {
  --showPasswordIconWidth: 2em;
  --showPasswordIconHeight: 1.5em;
  --showPasswordIconMargin: 2em;
}

body {
  margin: 0;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: #444444;
  letter-spacing: 0.08em;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/******************************************************* COMMON STYLES *****************************************/
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-weight: 700;
  font-size: 2em;
  margin: 0;
}

h2 {
  font-weight: 400;
  font-size: 1.25em;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  margin: 0;
}

h3 {
  font-weight: 400;
  font-size: 1.0625em;  /* 17px */
  letter-spacing: 0.28em;
  text-transform: uppercase;
  margin: 0;
}

h4 {
  font-weight: 700;
  font-size: 0.9375em;  /* 15px */
  letter-spacing: 0.32em;
  text-transform: uppercase;
  margin: 0;
}

h5 {
  font-weight: 700;
  font-size: 0.8125em;  /* 13px */
  letter-spacing: 0.32em;
  text-transform: uppercase;
  margin: 0;
}

h6 {
  font-weight: 700;
  font-size: 0.6875em;  /* 11px */
  letter-spacing: 0.32em;
  text-transform: uppercase;
  margin: 0;
}

.headline {
  font-weight: 700;
  font-size: 1.15625em;  /* 18.5px */
}

.subhead {
  font-weight: 700;
  font-size: 0.8125em;  /* 13px */
  letter-spacing: 0.08em;
}

.spacer {
  margin-top: 1px;
  margin-bottom: 1px;
}

/******************************************************* COLUMN *****************************************/
.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* flex-start */
  align-items: stretch;
}

/******************************************************* ROW *****************************************/
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/******************************************************* SCREEN *****************************************/
.screen {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; /* row */
  justify-content: flex-start; /* center */
  align-items: stretch;
  overflow: auto;
}
/* @media (min-width: 45em) {
  .screen {
    align-items: center;
  }
} */

/******************************************************* COMPONENT: BANNER **************************/
.banner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 1em;
  background-color: #F0F0F0;
  border-color: #D3D3D3;
}

.banner .subtitle {
  font: 'Lato';
  font-weight: 300;
  font-size: 1em;
  color: black;
}

.banner .title {
  font: 'Lato';
  font-weight: 700;
  font-size: 2em;
  color: black;
}

.banner .icon {
  height: 1.375em;
  width: 1.375em;
}

.banner #middle {
  display: none;
}
@media (min-width: 45em) {
  .banner #middle {
    display: flex;
  }
}

/******************************************************* COMPONENT: PAGE **************************/
.page {
  box-sizing: border-box;
  /* min-width: 100%;
  min-height: 100%; */
  max-width: 37.5em; /* 600px */
  min-width: 20em;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  flex: 1 1 auto;
  background: linear-gradient(to bottom, #FFFFFF, #F0F1F5);
}

/******************************************************* COMPONENT: PANEL **************************/
.panel {
  box-sizing: border-box;
  max-width: 37.5em; /* 600px */
  min-width: 20em;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  flex: 1 1 20em;
  background: linear-gradient(to bottom, #FFFFFF, #F0F1F5);
}
@media (min-width: 45em) {
  .panel {
    height: 62%;
    aspect-ratio: 1 / 2;
  }
}

/******************************************************* COMPONENT: STATIC FOOTER **************************/
.footerTitle {
  font: 'Lato';
  font-weight: 300;
  font-size: 1.375em;  /* 22px */
  letter-spacing: 0.24em;
}

.footerCaption {
  font-size: 0.8125em;  /* 15px */
  letter-spacing: 0.16em;
  color: #707070;
}

.staticFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 auto;
}

/******************************************************* COMPONENT: PAGE TITLE **************************/
.pageTitle {
  display: block;
  flex: 0 0 auto;
}

/******************************************************* BODY TEXT *****************************************/
.bodyText {
  padding: 0.5em;
  text-align: justify;
  font-family: 'Lato';
  font-size: 0.8125em;
  letter-spacing: 0.08em;
  color: #444
}

/******************************************************* COMPONENT: TEXT FIELD *****************************************/
.textField {
  margin-bottom: 0.5em;
}

.textField input[type='text'], .textField input[type='password']{
  box-sizing: border-box;
  width: 100%;
  min-height: 2em;
  aspect-ratio: 6 / 1;
  border: 0.5px solid #808080;
  border-radius: 1em;
  padding: 0.5em 0.5em 0.5em 1.5em;
  font-size: 1em;
  letter-spacing: 0.08em;
}
@media (min-width: 45em) {
  .textField input[type='text'], .textField input[type='password'] {
    aspect-ratio: 8 / 1;
  }
}

.textField input[type='text']::placeholder, .textField input[type='password']::placeholder {
  font-size: 0.8125em;
  font-weight: 400;
  letter-spacing: 0.24em;
  color: #808080;
  opacity: 1;
}

.textField .fieldInstr {
  display: none;
  font-family: 'Lato';
  font-size: 0.8125em;
  margin-bottom: 0.5em;
  margin-left: 1em;
  letter-spacing: 0.08em;
  color:#808080;
}

.textField:focus-within .fieldInstr {
  display: block;
}

/******************************************************* COMPONENT: PASSWORD FIELD *****************************************/
.passwordField {
  margin-bottom: 0.5em;
}

.passwordFieldContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding-right: var(--showPasswordIconMargin); */
}

.passwordField .fieldInstr {
  display: none;
  font-family: 'Lato';
  font-size: 0.8125em;
  margin-bottom: 0.5em;
  margin-left: 1em;
  letter-spacing: 0.08em;
  color:#808080;
}

.passwordField:focus-within .fieldInstr {
  display: block;
}

.passwordField input[type='password'], input[type='text'] {
  box-sizing: border-box;
  width: 100%;
  min-height: 2em;
  aspect-ratio: 6 / 1;
  border: 0.5px solid #808080;
  border-radius: 1em;
  padding: 0.5em 0.5em 0.5em 1.5em;
  font-size: 1em;
  font-weight: 400;
  letter-spacing: 0.08em;
}
@media (min-width: 45em) {
  .passwordField input[type='password'], input[type='text'] {
    aspect-ratio: 8 / 1;
  }
}

.passwordField input[type='password'], input[type='text'] {
  margin-right: calc(-1 * (var(--showPasswordIconWidth) + var(--showPasswordIconMargin)));
}

.passwordField input[type='password']::placeholder, input[type='text']::placeholder {
  font-size: 0.8125em;
  font-weight: 400;
  letter-spacing: 0.24em;
  color: #808080;
  opacity: 1;
}

.passwordField .showPasswordBtn {
  display: none;
  float: right;
  padding-right: 2em;
  top: 50%;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.passwordField:focus-within .showPasswordBtn {
  display: inline-block;
}

.showPasswordIcon {
  width: var(--showPasswordIconWidth);
  height: var(--showPasswordIconHeight);
}

/******************************************************* COMPONENT: TEXT BUTTON **************************/
.textButton {
  font-family: Lato;
  font-weight: 400;
  font-size: 0.9375em;
  letter-spacing: 0.16em;
  color: #5A8DC2;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.textButton:disabled {
  color: #808080;
}

/******************************************************* COMPONENT: STANDARD BUTTON **************************/
.standardButton {
  width: 100%;
  aspect-ratio: 8 / 1;
  margin: 0;
  border: 0.5px #5A8DC2;
  border-radius: 0.5em;
  background-color: #5A8DC2;
  font-size: 0.9375em;
  font-family: 'Lato';
  font-weight: 700;
  letter-spacing: 0.24em;
  color: white;  
}
@media (min-width: 45em) {
  .standardButton {
    aspect-ratio: 10 / 1;
  }
}

.standardButton:disabled {
  background-color: #e3e3e5;
}

.errorMsg {
  font-family: 'Lato';
  font-size: 0.8125em;
  margin-bottom: 0.5em;
  margin-left: 1em;
  letter-spacing: 0.08em;
  color:red;
}

/******************************************************* LOGIN PAGE *****************************************/
#teaserPanel {
  display: none;
  max-width:37.5em;
  height: 6em;
  width: 17em;
  background-color: #F0F1F5;
}
@media (min-width: 45em) {
  #teaserPanel {
    display: block;
    margin-right: 15%;
  }
}

#teaserItem {
  display: none;
}
@media (min-height: 35em) {
  #teaserItem {
    display: flex;
    justify-content: center;
    flex: 0 0 auto;
  }
  #teaserItem img {
    border-radius: 0.5em;
  }
}

/******************************************************* HOME PAGE *****************************************/

#homeScreen {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
}

@media (min-width: 45em) {
  #loginScreen {
    align-items: center;
  }
}

/******************************************************* SIGNUP PAGE *****************************************/
#signUpScreen {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
}
@media (min-width: 45em) {
  #signUpScreen {
    align-items: center;
  }
}

#signUpPanel {
  box-sizing: border-box;
  max-width: 37.5em; /* 600px */
  min-width: 20em;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  flex: 1 1 20em;
  background: linear-gradient(to bottom, #FFFFFF, #F0F1F5);
}
@media (min-width: 45em) {
  #signUpPanel {
    height: 62%;
    aspect-ratio: 1 / 2;
    align-items: stretch;
  }
}
/******************************************************* END *****************************************/

/* @supports (height: env(safe-area-inset-bottom)) {
  #loginScreen {
      height: calc(100vh - env(safe-area-inset-bottom));
  }
} */

/* #loginPanel {
  box-sizing: border-box;
  max-width: 37.5em; 
  min-width: 20em;
  padding: 1.5em 1.5em 3em 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 20em;
  background: linear-gradient(to bottom, #FFFFFF, #F0F1F5);
}
@media (min-width: 45em) {
  #loginPanel {
    height: 62%;
    aspect-ratio: 1 / 2;
    padding: 1.5em;
  }
} */

/* #loginTitle {
  display: block;
  flex: 0 0 auto;
} */

/* #loginFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 auto;
} */

/* input[type='text'], input[type='password'] {
  box-sizing: border-box;
  width: 100%;
  min-height: 2em;
  aspect-ratio: 6 / 1;
  border: 0.5px solid #808080;
  border-radius: 1em;
  padding: 0.5em 0.5em 0.5em 1.5em;
  font-size: 1em;
  font-weight: 700;
  letter-spacing: 0.16em;
}
@media (min-width: 45em) {
  input[type='text'], input[type='password'] {
    aspect-ratio: 8 / 1;
  }
}

input#password {
  margin-right: calc(-1 * (var(--showPasswordIconWidth) + var(--showPasswordIconMargin)));
}

input[type='text']::placeholder, input[type='password']::placeholder {
  font-size: 0.8125em;
  font-weight: 400;
  letter-spacing: 0.24em;
  color: #808080;
  opacity: 1;
} */

/* #passwordContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding-right: var(--showPasswordIconMargin); */
/*} */

/* #showPasswordBtn {
  display: none; */
  /* float: right;
  padding-right: 2em;
  top: 50%;
  cursor: pointer; */
/*}*/

/* REMOVE? */
/* #registerButton {
  width: 48%;
  aspect-ratio: 4 / 1;
  margin: 0 2% 0 0;
  border: 0.5px #e3e3e5;
  border-radius: 0.5em;
  background-color: #e3e3e5;
  font-size: 0.8125em;
  font-family: 'Lato';
  letter-spacing: 0.24em;
  color: #707070;  
} */

/* #loginButton {
  width: 100%;
  aspect-ratio: 8 / 1;
  border: 0.5px #5A8DC2;
  border-radius: 0.5em;
  background-color: #5A8DC2;
  font-size: 0.9375em;
  font-family: 'Lato';
  font-weight: 700;
  letter-spacing: 0.24em;
  color: white;  
}
@media (min-width: 45em) {
  #loginButton, #registerButton {
    aspect-ratio: 8 / 1;
  }
} */

/* #keepMeSignedInDiv, #forgotPasswordDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0.25em;
}

#keepMeSignedInDiv {
  font-family: 'Lato';
  font-size: 0.9375em;
  letter-spacing: 0.16em;
}

#keepMeSignedInDiv input[type="checkbox"] {
  margin-right: 0.5em;
} */

/* #forgotPasswordDiv {
  margin-left: 4%;
} */

/* #passwordContainer:focus-within #showPasswordBtn {
  display: inline-block;
  float: right;
  padding-right: 2em;
  top: 50%;
  cursor: pointer;
} */

/* 
#showPasswordIcon {
  width: var(--showPasswordIconWidth);
  height: var(--showPasswordIconHeight);
} */

/* @media (min-width: 45em) {
  #teaserPanel {
    float: left;
    width: 50%;
    height: 108px;
    background-color: #F0F1F5;
    display: block;
  }
  #loginContainer {
    box-sizing: border-box;
    max-width: 37.5em;
    min-width: 20em;
    padding: 3em 1.5em 6em 1.5em;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    flex: 1 1 20em;
  }
} */

/* #loginBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 0 0 auto;
  align-self: stretch;
}

#loginButtonsDiv {
  width: 100%;
}

#loginItemsDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
} */
